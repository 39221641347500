/*
| Debounce JS
|
|
*/
$(window).load($.debounce(200,function(){
	$('input:-webkit-autofill').each(function(){
		if ($(this).val().length !== "") {
			$(this).siblings('label, i').addClass('active');
		}
	});
}));


$('#loading-image').bind('ajaxStart', function(){
	$(this).addClass('active');
}).bind('ajaxStop', function(){
	$(this).removeClass('active');
});

$.ajaxSetup({
	headers: { 'Locale' : $('meta[name=_locale]').attr('content') },
    beforeSend: function(xhr) {
        if (Cookies.get('apptoken')) {
			xhr.setRequestHeader('Authorization', 'Bearer ' +  Cookies.get('apptoken'))
		}
    }
});

$(document).ajaxSuccess(function( event, xhr, settings ) {
	var token = xhr.getResponseHeader('Authorization');
	if(token){
		Cookies.set('apptoken', token);
	}
});

function isNumber(evt) {
	evt = (evt) ? evt : window.event;
	var charCode = (evt.which) ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		return false;
	}
	return true;
}

function showProgress(active){
	if (typeof(active)==='undefined') active = true;

	if(active){
		$('#loading-image').addClass("active");
		$("button[name='action']").addClass("disabled");
	}else{
		$('#loading-image').removeClass("active");
		$("button[name='action']").removeClass("disabled");
	}
}

function setMessage(id, text, type){
	//success
	if(type == 1){
		$(id).html('<span class="error green-text">'+text+'</span>');
	}else if(type == 0){
		$(id).html('<span class="error red-text">'+text+'</span>');
	}else{
		$(id).html('<span class="error red-text">Oops.. unable to process, please refresh page or Contact CS</span>');
	}
}

function updateDeposit(value){
	$('#deposit-label').html(value);
}

function redirect(response){
	if(response == 'token_not_provided'){
		alert('Session not provided, please login again');
	}else if(response == 'token_expired'){
		alert('Session expired, please login again');
	}else{
		alert(response + ', please login again..');
	}
	window.location.href = '//'+window.location.hostname+'/logout';
}

function refreshToken(){

}